import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.css';
import logo_zt from '../logo_zt.png';
import logo_ztw from '../logo_ztw.png';
import logo_magma from '../logo_magma.jpg';
import logo_bauboeck from '../logo_bauboeck.png';
import logo_matulik from '../logo_matulik.png';
import logo_ploederl from '../logo_ploederl.png';
import logo_wimmesberger from '../logo_wimmesberger.png';

const Architects = () => (
  <Container>
    <div className="textbox">
      <h5>Die Architekten der Innviertler Künstlergilde</h5>
      <Row className="mt-3">
        <Col md="auto">
          <a href="http://www.architekt-bauboeck.at/" target="_blank">
            <Card>
              <Card.Img src={logo_bauboeck} className="logo_arch" />
              <div className="m-1">DI Werner BAUBÖCK</div>
            </Card>
          </a>
        </Col>
        <Col md="auto">
          <a href="https://www.matulik.at/" target="_blank">
            <Card>
              <Card.Img variant="top" src={logo_matulik} className="logo_arch" />
              <div className="m-1">DI Andreas MATULIK</div>
            </Card>
          </a>
        </Col>
        <Col md="auto">
          <a href="http://www.pau.at/" target="_blank">
            <Card>
              <Card.Img variant="top" src={logo_ploederl} />
              <div className="m-1">DI Heinz PLÖDERL</div>
            </Card>
          </a>
        </Col>
        <Col md="auto">
          <a href="http://www.hlw-architekten.at/" target="_blank">
            <Card>
              <Card.Img variant="top" src={logo_wimmesberger} className="logo_arch" />
              <div className="m-1">Mag.arch Peter WIMMESBERGER</div>
            </Card>
          </a>
        </Col>
      </Row>

      <br /><br />
      <h5>Mitarbeit:</h5>
      Inhalte und Ausstellungskonzept: DI Florian Bauböck, DI Max Bauböck <br />
      Architektenpräsentationen: Bernhard Matulik <br />
      Modell: Mag.arch Peter Wimmesberger <br /><br />
      Vielen Dank an: <br />
      DI Oliver Brantner <br />
      DI Katharina Schachinger <br />
      Dipl.-Päd. Günter Moser, BEd <br />
      Sebastian Bauböck<br />
      <a href="https://www.sebastianmarschall.com" target="_blank">Sebastian Marschall</a> <br />
      Stadtgemeinde Ried <br />
      &amp; das Team der IKG <br />
      <br /><br />
      <h5>Unterstützt von:</h5>
      <a href="https://www.arching-zt.at" target="_blank"><img src={logo_zt} className="logo mt-3" /> <br /></a>
      <a href="http://ztw.at/" target="_blank"><img src={logo_ztw} className="logo mt-3" /> <br /></a>
      <a href="https://www.magma-tools.com/" target="_blank"><img src={logo_magma} className="logo mt-3" /> <br /></a>
    </div>
  </Container>
);

export default Architects;