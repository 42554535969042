import React from 'react';
import Container from 'react-bootstrap/Container';
import './index.css';

const Concept = () => (
  <Container>
    <div className="textbox">
      <h3>Konzept</h3>
      myRiad ist eine Gemeinschaftsausstellung der Architekten Werner Bauböck, Andreas Matulik, Heinz Plöderl und Peter Wimmesberger. Die vier Mitglieder der Innviertler Künstlergilde beschlossen im Rahmen der Architekturtage 2019 eine Ausstellung zu Organisieren.<br/>
      „Raum Macht Klima“ als zentrales Thema der Architekturtage 2019 wird in einem städtebaulichen Zusammenhang gesetzt. Stadtplanung als zentrales Instrument der Politik und Architekten / Raumplaner ist von enormer Bedeutung sowohl für die gesamte Bevölkerung als auch für das ökologische und ökonomische Gefüge. Öffentliche Räume definieren die Stadt und bestimmen die soziale Interaktion, die Nutzung, das Klima sowie das Erscheinungsbild. Raum für Menschen, Raum für Natur, Raum für Verkehr und Raum für Architektur wird aber sehr individuell aufgefasst und beurteilt. Jeder bildet sich eine Meinung und hat etwas zum Thema zu sagen. Wir nehmen das zum Anlass und wollen mit einer interaktiven Installation die Bewohner und Nutzer der Stadt auffordern diese nach ihren Wünschen und Träumen zu verändern. Ein gewisses Maß an Fantasie und Utopie ist beinahe Voraussetzung um sich an das „Redesign“ der Stadt heranzuwagen. In einem abstrahierten Stadtmodell kann der Besucher aktiv in die Gestaltung und Anordnung der Rieder Innenstadt eingreifen, diese individuell gestalten und mit einer Botschaft für die anderen Besucher hinterlassen.<br />
      Ziel soll es sein, dass sich die Besucher mit dem Thema der Stadtplanung intensiv auseinandersetzen. Ein innerlicher Diskurs wird ebenso entstehen wie eine breite Diskussion. Während der Ausstellungsdauer ist jeder eingeladen am „Redesign“ teilzuhaben und seine Visionen auch kommentiert zu hinterlassen. Die gesamte Installation wird möglichst einfach und intuitiv gehalten, die relevanten Themen in einem subtilen Rahmen transportiert.<br />
      Am Ende findet sich hoffentlich jeder im Querschnitt der unzähligen Möglichkeiten wieder.<br />
      <br />
      <br />
      <h5>Themenschwerpunkte</h5><br />
      <li>Nutzungen:</li><br />
      Die Stadt beherbergt verschiedenste Nutzungen und stellt sich als komplexes Geflecht dar. Es entstehen verschiedenste Anforderungen, die oft sehr konträr sind. Eine Darstellung dieser Situation reflektiert einen gewissen “Ist- Zustand“ der Stadt im Allgemeinen.<br />
      <br />
      <li>Freiraum:</li><br />
      Öffentliche Plätze und Freiräume definieren die Stadt. Sie sind geprägt durch Nutzung, Zonierung, Fassaden, Oberflächen, Begrünung und vieles mehr. Geformt durch gewachsene Strukturen bergen die Plätze einer Stadt oft großes 	Potential.<br />
      <br />
      <li>Mobilität:</li><br />
      Verkehr und Autos / Parkplätze sind oft eine Sackgasse in städtebaulichen Diskussionen und nehmen einen großen Stellenwert im raumplanerischen Dialog ein. Es gibt bereits viele zukunftsträchtige Konzepte und Ideen, diese reichen von Carsharing und E- Verkehr (Autos, Busse und LKWs) bis hin zu den selbstfahrenden Autos, die uns bei Bedarf vor der Tür abholen. Dieser Ausblick in die Zukunft könnte ein Anlass sein Potentiale zu erschließen und den “Ist-Zustand” zu verbessern.<br />
    </div>
  </Container>
);

export default Concept;