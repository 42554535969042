import * as firebase from 'firebase';
import firestore from 'firebase/firestore'
import storage from 'firebase/storage'

const config = {
    apiKey: "AIzaSyDAuicagQWy6gFI0CfDXjFn0kFpzaiF9mU",
    authDomain: "myriad-2307b.firebaseapp.com",
    databaseURL: "https://myriad-2307b.firebaseio.com",
    projectId: "myriad-2307b",
    storageBucket: "myriad-2307b.appspot.com",
    messagingSenderId: "356915789328"
};
firebase.initializeApp(config);


export default firebase;