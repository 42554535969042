import React from 'react';
import Container from 'react-bootstrap/Container';
import Slider from '../Slider';

const Landing = () => (
    <Container>
        <Slider />
    </Container>
);

export default Landing;