import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table'
import './index.css';

const Contact = () => (
  <Container>
    <div className="textbox">
      <h3>Kontakt</h3><br />
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Kontakt, Anmeldung und Sondertermine:</td>
            <td><a href="mailto:myriad@gmx.at">myriad@gmx.at</a></td>
          </tr>
          <tr>
            <td>Ort:</td>
            <td>Begegnungsort Gilde am Kirchenplatz 11, 4910 Ried i.I.</td>
          </tr>
          <tr>
            <td>Öffnungszeiten:</td>
            <td>jeden Mittwoch 16:00 – 20:00<br/>Fr. 24.05.19 &amp; Sa 25.05.19 je 10:00 – 19:00 <br /></td>
          </tr>
          <tr>
            <td>Vernissage:</td>
            <td>Mi 15.05.2019 19:00</td>
          </tr>
          <tr>
            <td>Finissage:</td>
            <td>Mi 03.07.2019 19:00</td>
          </tr>
        </tbody>
      </Table>
    </div>
  </Container>
);

export default Contact;