import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo_myriad from '../logo_myriad.jpg';
import logo_ikg from '../logo_ikg.jpg';

import * as ROUTES from '../constants/routes';
import Container from 'react-bootstrap/Container';

const Navigation = () => (
  <Container className="mt-3 mb-5">
    <Navbar bg="white" variant="light" expand="lg">
      <Navbar.Brand href={ROUTES.LANDING}><img className="NavBarLogo" src={logo_myriad} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href={ROUTES.LANDING}>Ausstellung</Nav.Link>
          <Nav.Link href={ROUTES.CONCEPT}>Konzept</Nav.Link>
          <Nav.Link href={ROUTES.ARCHITECTS}>Architekten</Nav.Link>
          <Nav.Link href={ROUTES.CONTACT}>Kontakt</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav.Link href={ROUTES.CONTACT}><b>Finissage am 3. Juli</b></Nav.Link>
      <Navbar.Brand href="https://www.innviertler-kuenstlergilde.at/" target="_blank"><img className="NavBarLogo" src={logo_ikg} /></Navbar.Brand>
    </Navbar>
  </Container>
);

export default Navigation;