import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from '../Navigation';
import Contact from '../Contact';
import Concept from '../Concept';
import Slider from '../Slider';

import * as ROUTES from '../constants/routes';
import Architects from '../Architects';
import Landing from '../Landing';

const App = () => (
  <Router>
    <Switch>

      <Route exact path="/showroom/:mode(random|newest)" component={getSlider} />

      <Route>
        <div>
          <Navigation />
          <Switch>
            <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route path={ROUTES.CONTACT} component={Contact} />
            <Route path={ROUTES.ARCHITECTS} component={Architects} />
            <Route path={ROUTES.CONCEPT} component={Concept} />
          </Switch>
          <div className="mt-5" />
        </div>

      </Route>
    </Switch>
  </Router>
);

function getSlider({ match }) {
  const onlyNewest = match.url.includes('newest');
  return (
    <Slider isShowroom={true} onlyNewest={onlyNewest} />
  );
}


export default App;