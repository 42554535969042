
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container'
import firebase from '../Firebase';
import plakat from '../plakat.jpg';
import scissor from '../scissor.png';
import './index.css';

class Slider extends Component {

  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('entries').orderBy('createdAt', 'desc');
    this.unsubscribe = null;
    this.state = {
      entries: null
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    let entries = [];
    querySnapshot.forEach((doc) => {
      const { id, name, input, path } = doc.data();
      entries.push({
        key: doc.id,
        id,
        name,
        input,
        path
      });
    });

    if (this.props.onlyNewest) {
      entries = entries.slice(0, 1);
    } else {
      shuffle(entries);
    }

    this.setState({
      entries
    });
  }



  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }


  render() {
    if (!this.state.entries) {
      return (
        <div />
      )
    }

    return (
      <div>
        <Carousel controls={!this.props.isShowroom} pauseOnHover={!this.props.isShowroom} indicators={false} interval="30000">
          {this.renderItems(this.state.entries)}
        </Carousel>
      </div>
    );
  }

  renderItems(data) {

    let classSuffix = ""
    if (this.props.isShowroom) {
      classSuffix += "Showroom"
    }

    return data.map((item) => {
      let text = "";
      if(item.input.length > 0) {
        text = "\"" + item.input + "\"";
      }
      return (
        <Carousel.Item key={item.id}>
          <div className="imageParent">
            <img src={scissor} className={'scissor' + classSuffix} />
            <img src={item.path} className={'shape' + classSuffix} fluid />
          </div>
          {/* 
          <Carousel.Caption>
            <p className="caption">{item.input}</p>
          </Carousel.Caption>
           */}
          <div className={'captionBox' + classSuffix}><p className={'captionText' + classSuffix}>{text}</p></div>
        </Carousel.Item>
      );
    })
  }

}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // swap elements
  }
}

export default Slider;
